<template>
  <!-- category breadcrumb -->
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <div v-if="error" class="alert alert-danger">{{error}}</div>
    <div v-if="message">
        <div class="alert alert-success">{{message}}</div>
    </div>
    <form v-if="!error && !message" @submit.prevent="handleSubmit">
      <h2 class="mb-4 mt-2">Quotation Request</h2>
      
      <div id="form">
          <div class="row">
              <div class="col-md-1">Item No:</div>
              <div class="col-md-2">{{item.itmno}}</div>
          </div>
          <div class="row">
              <div class="col-md-1">Description:</div>
              <div class="col-md-11">{{item.title}}</div>
          </div>
          <div class="row">
              <div class="col-md-1">Quantity:</div>
              <div class="col-md-10">
                  <!-- <input type="number" class="form-control form-control-sm" min="1" required v-model="quantity"> -->
                  <div class="input-group input-group-sm mt-1 mb-3">
                  <button class="btn qty_btn" type="button" @click="handleDecreaseQty()"><i class="bi bi-dash big"></i></button>
                  <input type="number" class="qty_input_sm" v-model="quantity" min="1" required>
                  <button class="btn qty_btn" type="button" @click="handleIncreaseQty()"><i class="bi bi-plus big"></i></button>
                  </div>
                  
              </div>
          </div>
          <div class="row">
             <div class="col-md-1">Configuration: </div>
             <div class="col-md-11">
                 <!-- <div><a class="pointer" @click="toggleConfigDetail">View</a>  </div> -->
                 <div>
                    
                     <div v-for="row in component_items">
                        <span class="fw-bold">{{row.group}}:</span> {{row.title}} | {{row.itmno}} ({{row.qty}} pcs)
                        <div v-if="row.addon_itmno">
                            <span class="badge bg-info">Add-on Item</span> <span class="fw-bold">{{row.addon_item.group}}:</span> {{row.addon_item.title}} | {{row.addon_item.itmno}} ({{row.addon_item.qty}} pcs)
                        </div>
                     </div>
                     <div class="mt-3">
                        <button type="button" class="btn btn-primary btn-sm orange" @click="handleChangeConfig">Change Configuration</button>
                     </div>
                 </div>
             </div>
          </div>
          <!-- <div class="mt-4 fw-bold">Customer Information</div> -->
          <h2 class="my-5">Customer Information</h2>
          <div class="row">
            <div class="col-md-2">Customer ID <span v-if="is_customer == false">(Optional)</span>:</div>
            <div class="col-md-3">
                <input v-if="is_customer" type="text" class="form-control form-control-sm" required v-model="custno">
                <input v-else type="text" class="form-control form-control-sm" v-model="custno">
            </div>
          </div> <div class="row">
            <div class="col-md-2">Contact (Optional):</div>
            <div class="col-md-3">
                <input type="text" class="form-control form-control-sm" v-model="cust_name">
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">Email:</div>
            <div class="col-md-3">
                <input type="text" class="form-control form-control-sm" required v-model="cust_email">
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">Phone:</div>
            <div class="col-md-3">
                <input type="text" class="form-control form-control-sm" required v-model="cust_phone">
            </div>
          </div>
          <!-- <div class="fw-bold">Company Address</div> -->
          <h2 class="my-5">Company Address</h2>
          <div class="mt-4 mb-4" v-if="is_customer">
              <div class=""><input type="radio" name="company_address" value="default company" checked  @click="handleClick($event)"> Use default company address</div>
              <!-- <div class="p-3" :class="{'visually-hidden': hideDefaultCompany}"> -->
              <div class="p-3" v-if="!hideDefaultCompany">
                  {{customer.company}} <br/>
                  {{customer.addr1}} <span v-if="customer.addr2">, {{customer.addr2}}</span><br/> 
                  {{customer.city}}, {{customer.state}} {{customer.zip}} <br/>
                  Phone: {{customer.phone}}
              </div>
          </div>
          <div class="mt-4 mb-4">
              <div v-if="is_customer"><input type="radio" name="company_address" value="different company" @click="handleClick($event)"> Enter a different company address</div>
              <!-- <div class="p-3" :class="{'visually-hidden': !hideDefaultCompany}"> -->
              <div class="p-3" v-if="hideDefaultCompany">
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="company" class="col-form-label"
                        >Company/Name:</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="company" id="inputCompany" class="form-control" required maxlength="35"/>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address1" class="col-form-label"
                        >Address Line 1:</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="addr1" id="inputAddress1" class="form-control" required maxlength="35"/>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address2" class="col-form-label"
                        >Address Line 2: (Optional)</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="addr2" id="inputAddress2" class="form-control" maxlength="35" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Country" class="col-form-label">Country:</label>
                    </div>
                    <div class="col-sm-5">
                        <select v-model="country" class="form-select" aria-label="Default select example" id="country" name="country" @change="onChange($event)" required>
                            <option selected>Select a Country</option>                      
                            <option v-for="country in countryList" :key="country" :value="country.iso" >{{country.printable_name}}</option>
                        </select>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="City" class="col-form-label">City:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="city" id="inputCity" class="form-control" required maxlength="33" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="State" class="col-form-label">State:</label>
                    </div>
                    <div class="col-sm-5">
                        <select v-if="country == 'US'" v-model="state" class="form-select"  aria-label="Default select example" id="state" name="state" required>
                            <option selected>Select a State</option>                      
                            <option v-for="state in stateList" :key="state" :value="state.abbrev">{{state.name}}</option>
                        </select>
                        <input v-if="country != 'US'" type="text" class="form-select" v-model="state" required>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Zip" class="col-form-label">Zipcode:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="zip" id="inputZip" class="form-control" required maxlength="10" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Phone" class="col-form-label">Phone:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="phone" id="inputPhone" class="form-control" required maxlength="17"/>
                    </div>
                    </div>
                </div>
          </div>
          <!-- <div class="fw-bold">Shipping Address</div> -->
          <h2 class="my-5">Shipping Address</h2>
          <div class="mt-4 mb-4" v-if="is_customer">
              <div class=""><input type="radio" name="shipping_address" value="default shipping" checked @click="handleClick($event)"> Use default shipping address</div>
              <div class="p-3" v-if="!hideDefaultShipping">
                  {{customer.company}} <br/>
                  {{customer.saddr1}} <span v-if="customer.saddr2">, {{customers.addr2}}</span><br/> 
                  {{customer.scity}}, {{customer.sstate}} {{customer.szip}} <br/>
                  Phone: {{customer.phone}}
              </div>
          </div>
          <div class="mt-4 mb-4">
              <div v-if="is_customer"><input type="radio" name="shipping_address" value="different shipping" @click="handleClick($event)"> Enter a different shipping address</div>
              <div v-else><input type="checkbox" @click="ToggleShippingAddress" checked> Same as company address</div>
              <div class="p-3" v-if="hideDefaultShipping">
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="company" class="col-form-label">Company/Name:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="scompany" id="inputCompany" class="form-control" required maxlength="35"/>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address1" class="col-form-label"
                        >Address Line 1:</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="saddr1" id="inputAddress1" class="form-control" required maxlength="35"/>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address2" class="col-form-label"
                        >Address Line 2:(Optional)</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="saddr2" id="inputAddress2" class="form-control" maxlength="35" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Country" class="col-form-label">Country:</label>
                    </div>
                    <div class="col-sm-5">
                        <select v-model="scountry" class="form-select" aria-label="Default select example" id="country" name="country" @change="onChange2($event)" required>
                            <option selected>Select a Country</option>                      
                            <option v-for="country in countryList" :key="country" :value="country.iso" >{{country.printable_name}}</option>
                        </select>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="City" class="col-form-label">City:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="scity" id="inputCity" class="form-control" required maxlength="33" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="State" class="col-form-label">State:</label>
                    </div>
                    <div class="col-sm-5">
                            <select v-if="scountry == 'US'" v-model="sstate" class="form-select"  aria-label="Default select example" id="state" name="state" required>
                                <option selected>Select a State</option>                      
                                <option v-for="state in stateList" :key="state" :value="state.abbrev">{{state.name}}</option>
                            </select>
                            <input v-if="scountry != 'US'" type="text" class="form-select" v-model="sstate" required>
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Zip" class="col-form-label">Zipcode:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="szip" id="inputZip" class="form-control" required maxlength="10" />
                    </div>
                    </div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Phone" class="col-form-label">Phone:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="sphone" id="inputPhone" class="form-control" required maxlength="17"/>
                    </div>
                    </div>
                </div>
          </div>
          <!-- <h2 class="mb-4 mt-2">Delivery Preference</h2> -->
          <h2 class="my-5">Delivery Preference</h2>
          <div class="row mt-5">
            <div class="col-md-2">Shipping Method:</div>
            <div class="col-md-5">
                <select size="6" v-model="shipCode" name="shippingMethod" id="shippingMethod" class="form-control" required>
                    <option  v-for="v in shippingList" :key="v" :value=v[1]>{{v[0]}}</option>
                </select>
            </div>
          </div>
          <h2 class="my-5">Other Information (Optional)</h2>
          <div class="row mt-5">
            <div class="col-md-2">Special Instruction:</div>
            <div class="col-md-5">
                <textarea class="form-control" rows="5" v-model="note"></textarea>
            </div>
          </div>
      </div>
      
      <button v-if="waiting" class="btn btn-primary btn-lg" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Submit
        </button>
      <button v-else type="submit" class="btn btn-primary btn-lg">Submit</button>
<!-- <button type="submit" class="btn btn-primary btn-lg" @click="handleSubmit">Sumbit</button> -->
    </form>
  </div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { useRoute, useRouter } from "vue-router"
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getAPI, getAuthAPI } from "../../utils/axios-api"
import $ from 'jquery'

export default {
  name: "ConfigQuote",
  props: ['item', 'components', 'config_payload'],
  components: { BreadcrumbPage },
  setup(props, context) {
    //if the page is refreshed, props won't be passed in. In this case, redirect back to config details page.
    const route = useRoute()
    const router = useRouter()
    if ( props.item == undefined ) {
        router.push({name: 'ConfigDetails', query: { itmno: route.query.itmno } })
    }
    
    const quantity = ref(null)
    const custno = ref("")
    const cust_name = ref("")
    const cust_email = ref("")
    const cust_phone = ref("")
    var use_default_company = 0
    var use_default_shipping = 0
    const company = ref("")
    const addr1 = ref("")
    const addr2 = ref("")
    const country = ref("")
    const city = ref("")
    const state = ref("")
    const zip = ref("")
    const phone = ref("")
    const scompany = ref("")
    const saddr1 = ref("")
    const saddr2 = ref("")
    const scountry = ref("")
    const scity = ref("")
    const sstate = ref("")
    const szip = ref("")
    const sphone = ref("")
    const shipCode = ref("")
    const note = ref("")

    const waiting = ref(false)
    const error = ref(null)
    const message = ref(null)

    const countryList = ref(null)
    const stateList = ref(null)
    const shippingList = ref([])

    const hideConfigDetails = ref(true)
    const hideDefaultCompany = ref(false)
    const hideDefaultShipping = ref(false)
    const sameAsCompanyAddress = ref(true)

    const item = ref({})
    const component_items = ref([])
    var components
    var config_payload
    try {
        //item: system item info
        item.value = JSON.parse(props.item)
        //components: sytem item's components info
        components = JSON.parse(props.components)
        //config_payload
        config_payload = JSON.parse(props.config_payload)

        //set item quantity
        quantity.value = config_payload.quantity
        
        //get the list of component items into a list
        for (let group in components) {
            for (let type in components[group]["types"]) {
                for (var i=0; i < components[group]["types"][type]["items"].length; i++) {
                    if (components[group]["types"][type]["items"][i]["selected"]){
                        component_items.value.push(components[group]["types"][type]["items"][i])
                    }
                }
            }
        }

    }
    catch (e) {
        error.value = e//"Something is wrong."
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Configuration"})
    // breadcrumb_links.value.push({text: "System Configuration", link: "Config"})
    breadcrumb_links.value.push({text: "Quote"})
    breadcrumb_links.value.push({text: item.value.title})

    //for customer, pre-fill some fields
    const store = useStore()
    const is_customer = ref(store.getters.isCustomer)
    const customer = ref({})
    if (is_customer.value == true) {
        use_default_company = 1
        use_default_shipping = 1
        
        getAuthAPI.get('/account/my-account/')
        .then(response => {
            customer.value = response.data.results
            custno.value = customer.value.custno
            cust_email.value = customer.value.email 
            cust_phone.value = customer.value.phone 
        })
        .catch(err => {
            error.value = err.message
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    }
    else {
        hideDefaultCompany.value = true
    }

    //get country list, state list and shipping methods
    getAPI
    .get("/order/get-shipping-data/")
    .then(response => {
        countryList.value = response.data.country_list
        stateList.value = response.data.state_list
        let shippingMethods = response.data.ship_methods
        let code = ""
        for (let method in shippingMethods){
            if (method == "N/A") continue
            
            //get first character of ship code. For example: E:UPS-GROUND, E will be the value
            code = method.split(":")[0]
            shippingList.value.push([shippingMethods[method],code])          
        }
    })
    .catch(err => {})

    //onChange event for country dropdown for company address
    function onChange(event){
        let country = event.target.value
        if (country != 'US') state.value = ""
    }

    //onChange event for country dropdown for shipping address
    function onChange2(event){
        let country = event.target.value
        if (country != 'US') sstate.value = ""
    }

    //handle the switch between default company/shipping address and different company/shipping address
    function handleClick(event){
        if (event.target.value == "default company") {
            use_default_company = 1
            hideDefaultCompany.value = false
        }
        else if (event.target.value == "different company") {
            use_default_company = 0
            hideDefaultCompany.value = true
        }
        else if (event.target.value == "default shipping") {
            use_default_shipping = 1
            hideDefaultShipping.value = false
        }
        else if (event.target.value == "different shipping") {
            use_default_shipping = 0
            hideDefaultShipping.value = true
        }
    }

    //show or hide shipping address based on if it is same as company address
    function ToggleShippingAddress() {
        if (sameAsCompanyAddress.value == true) {
            sameAsCompanyAddress.value = false
            hideDefaultShipping.value = true
        }
        else {
            sameAsCompanyAddress.value = true
            hideDefaultShipping.value = false
        }
    }

    //show or hide configuration details
    function toggleConfigDetail() {
        if (hideConfigDetails.value == true) hideConfigDetails.value = false
        else hideConfigDetails.value = true
    }
    
    //when click "Change Configuration" button, return to confiuration details page and pass config_data back to it.
    function handleChangeConfig() {
        router.push({name: 'ConfigDetails', query: { itmno: route.query.itmno }, params: {config_payload: props.config_payload, components: props.components} })
    }

    const handleSubmit = async () => {
        waiting.value = true

        var payload = {}
        payload = generate_payload()
        
        var quoteAPI
        if (is_customer.value == true) quoteAPI = getAuthAPI
        else quoteAPI = getAPI
        quoteAPI.post('/order/quote-request-submit/', payload)
        .then(response => {
            waiting.value = false
            message.value = response.data.message
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(err => {
            error.value = err.message
            waiting.value = false
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        
    }

    function generate_payload() {
        var payload = {}
        payload["itmno"] = item.value.itmno
        payload["desc"] = item.value.title
        payload["quantity"] = quantity.value
        payload["system"] = 1
        payload["system_details"] = config_payload.system_details
        payload["custno"] = custno.value
        payload["contact"] = cust_name.value
        payload["email"] = cust_email.value
        payload["phone"] = cust_phone.value
        payload["shipcode"] = shipCode.value
        payload["customer_comments"] = note.value

        if (is_customer.value == true) {
            payload["use_default_billto"] = use_default_company == 1? true : false
            payload["use_default_shipto"] = use_default_shipping == 1? true : false

            if (use_default_company == 0) {
                payload["company"] = company.value
                payload["addr1"] = addr1.value
                payload["addr2"] = addr2.value
                payload["city"] = city.value
                payload["state"] = state.value
                payload["country"] = country.value
                payload["zipcode"] = zip.value
                payload["bphone"] = phone.value
            }

            if (use_default_shipping == 0) {
                payload["scompany"] = scompany.value
                payload["saddr1"] = saddr1.value
                payload["saddr2"] = saddr2.value
                payload["scity"] = scity.value
                payload["sstate"] = sstate.value
                payload["scountry"] = scountry.value
                payload["szipcode"] = szip.value
                payload["sphone"] = sphone.value
            }
            
        }
        else {
            payload["company"] = company.value
            payload["addr1"] = addr1.value
            payload["addr2"] = addr2.value
            payload["city"] = city.value
            payload["state"] = state.value
            payload["country"] = country.value
            payload["zipcode"] = zip.value
            payload["bphone"] = phone.value

            payload["shipto_billto_same"] = sameAsCompanyAddress.value
            if (sameAsCompanyAddress.value == false) {
                payload["scompany"] = scompany.value
                payload["saddr1"] = saddr1.value
                payload["saddr2"] = saddr2.value
                payload["scity"] = scity.value
                payload["sstate"] = sstate.value
                payload["scountry"] = scountry.value
                payload["szipcode"] = szip.value
                payload["sphone"] = sphone.value
            }
        }

        return payload

    }

    function handleIncreaseQty() {
        if (quantity.value) quantity.value = parseInt(quantity.value) + 1
        else quantity.value = 1
    }

    function handleDecreaseQty() {
        if (quantity.value) {
            if (quantity.value > 1)
                quantity.value = parseInt(quantity.value) - 1
            else
                quantity.value = 1

        }
    }
    return {
        quantity,
        custno,
        cust_name,
        cust_email,
        cust_phone,
        company,
        addr1,
        addr2,
        country,
        city,
        state,
        zip,
        phone,
        scompany,
        saddr1,
        saddr2,
        scountry,
        scity,
        sstate,
        szip,
        sphone,
        shipCode,
        note,
        waiting,
        error,
        message,
        breadcrumb_links,
        item, 
        component_items,
        countryList,
        stateList,
        shippingList,
        hideConfigDetails,
        hideDefaultCompany,
        hideDefaultShipping,
        sameAsCompanyAddress,
        is_customer,
        customer,
        onChange,
        onChange2,
        handleClick,
        ToggleShippingAddress,
        toggleConfigDetail,
        handleChangeConfig,
        handleSubmit,
        handleIncreaseQty,
        handleDecreaseQty
    }
  }
}
</script>

<style scoped>
#form .row {margin-bottom:10px;}
.pointer {cursor: pointer;}
.orange {background-color: #e56600 !important; border-color: #e56600 !important;}
.orange:hover {background-color: #cc5c02 !important; border-color: #cc5c02 !important;}
.qty_input_sm {width:50px; border:1px solid #ccc !important; text-align: center;}
.qty_btn {background-color: #ccc; border:1px solid #ccc;}
.qty_btn:hover {background-color: #888; color:#fff; border:1px solid #888;}
.qty_btn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  background-color: #ccc; border:1px solid #ccc;
  color:#000;
}
</style>